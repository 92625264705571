<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <number-value :value="volA" unit="\text{mL}" />
        solution of
        <number-value :value="concA" unit="\text{M}" />
        <chemical-latex content="Pb(NO3)2" />
        was mixed with
        <number-value :value="volB" unit="\text{mL}" />
        of
        <number-value :value="concB" unit="\text{M}" />
        <chemical-latex content="Na3PO4," />
        resulting in the reaction shown below:
      </p>

      <p class="pl-8 mb-5">
        <chemical-latex content="3 Pb(NO3)2(aq) + 2 Na3PO4(aq) -> Pb3(PO4)2(s) + 6 NaNO3(aq)" />
      </p>

      <p class="mb-2">
        a) What are the molecular weights of
        <chemical-latex content="Pb(NO3)2," />
        <chemical-latex content="Na3PO4," />
        <chemical-latex content="Pb3(PO4)2?" />
      </p>

      <calculation-input
        v-model="inputs.mwPbNO3"
        class="mb-2"
        prepend-text="$\ce{Pb(NO3)2:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.mwNaPO4"
        class="mb-2"
        prepend-text="$\ce{Na3PO4:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.mwPbPO4"
        class="mb-6"
        prepend-text="$\ce{Pb3(PO4)2:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the theoretical amount of
        <chemical-latex content="Pb3(PO4)2(s)" />
        formed in grams.
      </p>

      <calculation-input
        v-model="inputs.theoYield"
        class="mb-5"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Determine the amount of unused reactant in grams.</p>

      <calculation-input
        v-model="inputs.excess"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question259',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mwPbNO3: null,
        mwNaPO4: null,
        mwPbPO4: null,
        theoYield: null,
        excess: null,
      },
    };
  },
  computed: {
    volA() {
      return this.taskState.getValueBySymbol('volA').content;
    },
    volB() {
      return this.taskState.getValueBySymbol('volB').content;
    },
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concB() {
      return this.taskState.getValueBySymbol('concB').content;
    },
  },
};
</script>
